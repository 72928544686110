import { zodResolver } from '@hookform/resolvers/zod'
import { HTTPError } from '@qogita/canary-client'
import { Anchor } from '@qogita/ui/anchor'
import { Button } from '@qogita/ui/button'
import {
  Form,
  FormControl,
  FormFieldController,
  FormItem,
  FormLabel,
  FormMessage,
  FormProvider,
} from '@qogita/ui/form'
import { InlineNotification } from '@qogita/ui/inline-notification'
import { Input } from '@qogita/ui/input'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { useLoginMutation } from '#src/api/auth-queries'
import { EMAILS } from '#src/constants/copy'
import { InvalidPortalUserLoginError } from '#utils/errors'

const schema = z.object({
  email: z
    .string()
    .min(1, 'Please enter your email address.')
    .email('Please enter a valid email address.'),
  password: z.string().min(1, 'Please enter your password.'),
})

export const LoginForm = () => {
  const loginMutation = useLoginMutation()

  const form = useForm<z.infer<typeof schema>>({
    mode: 'onBlur',
    defaultValues: { email: '', password: '' },
    resolver: zodResolver(schema),
  })

  return (
    <FormProvider {...form}>
      <Form
        onSubmit={form.handleSubmit((data) => loginMutation.mutate(data))}
        className="flex w-full flex-col gap-3"
      >
        <FormFieldController
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email address</FormLabel>
              <FormControl>
                <Input {...field} type="email" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormFieldController
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input {...field} placeholder="" type="password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex flex-col gap-3">
          <Anchor
            href="https://www.qogita.com/reset-password/"
            className="self-end font-medium"
          >
            Forgot password?
          </Anchor>

          {loginMutation.error instanceof HTTPError ? (
            <>
              {loginMutation.error.response.status === 401 ? (
                <InlineNotification variant="error">
                  <InlineNotification.Icon />
                  <InlineNotification.Content>
                    <InlineNotification.Title className="font-bold">
                      There was a problem
                    </InlineNotification.Title>
                    <InlineNotification.Description>
                      The email address or password you entered is invalid.
                    </InlineNotification.Description>
                  </InlineNotification.Content>
                </InlineNotification>
              ) : (
                <InlineNotification variant="error">
                  <InlineNotification.Icon />
                  <InlineNotification.Content>
                    <InlineNotification.Title className="font-bold">
                      There was a problem
                    </InlineNotification.Title>
                    <InlineNotification.Description>
                      There was an error logging in. Please try again or reach
                      out to {EMAILS.procurement}.
                    </InlineNotification.Description>
                  </InlineNotification.Content>
                </InlineNotification>
              )}
            </>
          ) : loginMutation.error instanceof InvalidPortalUserLoginError ? (
            <InlineNotification variant="error">
              <InlineNotification.Icon />
              <InlineNotification.Content>
                <InlineNotification.Title className="font-bold">
                  There was a problem
                </InlineNotification.Title>
                <InlineNotification.Description>
                  This email is not associated with a seller account. Please try
                  another email address or sign up below.
                </InlineNotification.Description>
              </InlineNotification.Content>
            </InlineNotification>
          ) : null}

          <Button
            type="submit"
            isLoading={loginMutation.isPending || form.formState.isSubmitting}
          >
            <Button.Loader>Login</Button.Loader>
          </Button>
        </div>
      </Form>
    </FormProvider>
  )
}
