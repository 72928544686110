import { Button } from '@qogita/ui/button'
import { PopupButton } from '@typeform/embed-react'
import Link from 'next/link'
import { useEffect } from 'react'

import { LoadingScreen } from '#components/shared/LoadingScreen'
import { NEW_SELLER_FORM_TYPEFORM_ID } from '#constants'
import { useAppRedirection, useAuthStatus } from '#contexts/Authentication'
import { Logo } from '#src/components/shared/Logo'
import { Seo } from '#src/components/shared/Seo'
import {
  trackLoginPageTypeformOpened,
  trackSignUpFormSubmitted,
} from '#src/utils/report/tracking'
import { Page as NextPage } from '#types/next'

import { LoginForm } from './LoginForm'

const Page: NextPage = () => {
  const authState = useAuthStatus()
  const { redirectVisitor } = useAppRedirection(false)

  useEffect(() => {
    if (authState === 'VERIFIED') {
      redirectVisitor()
    }
  }, [authState, redirectVisitor])

  if (['VERIFIED'].includes(authState)) return <LoadingScreen />

  return (
    <>
      <Seo title="Login" />
      <main className="flex h-screen flex-col items-center justify-center bg-gray-100">
        <div className="flex w-full max-w-md flex-col items-center justify-center">
          <Link href="/" passHref>
            <Logo />
          </Link>
          <h1 className="mb-12 mt-6 text-center text-3xl sm:mt-12">
            Login to your account
          </h1>
          {/* Using the "magic" breakpoint of 448px here (min-[448px]) is so that the corners of the form are rounded as soon as the form stretches to its maximum width (max-w-md which is 448px). This handles screen sizes between 448px and up */}
          <div className="flex w-full flex-col items-center gap-4 bg-white p-6 shadow min-[448px]:rounded-lg">
            <LoginForm />
            <PopupButton
              type="button"
              onSubmit={(event) => {
                trackSignUpFormSubmitted({
                  typeformId: NEW_SELLER_FORM_TYPEFORM_ID,
                  typeformResponseId: event.responseId,
                })
              }}
              // @ts-expect-error the folloing is a valid element
              as={({ onClick, ...buttonProps }) => (
                <p>
                  New to Qogita?{' '}
                  <Button
                    {...buttonProps}
                    color="primary"
                    appearance="text"
                    type="button"
                    onClick={() => {
                      onClick?.()
                      trackLoginPageTypeformOpened({
                        typeformID: NEW_SELLER_FORM_TYPEFORM_ID,
                      })
                    }}
                  >
                    Get started
                  </Button>
                </p>
              )}
              id={NEW_SELLER_FORM_TYPEFORM_ID}
            />
          </div>
        </div>
      </main>
    </>
  )
}

Page.options = {
  public: true,
}

export { Page }
